/*
Replace
counter with your resource Name :) Take care about different writings!
/!\ Counter counter Counters counters /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";
import { compact } from "lodash";

export const counterSlice = createSlice({
  name: "counters",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listCounters,
  show: showCounter,
  create: createCounter,
  update: updateCounter,
} = counterSlice.actions;

export const actions = counterSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const counters = (state) =>
  state.counters.idsList.map((id) => state.counters.dictionary[id]);
export const counter = (state) =>
  state.counters.showId && state.counters.dictionary[state.counters.showId];
export const counterAttributes = (state) => counter(state)?.attributes;
export const counterRelationships = (state) => counter(state)?.relationships;
export const counterId = (state) => state.counters.showId;

export const getCounter = createSelector(
  (state) => state.counters.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getCounterAttributes = createSelector(
  (state) => state.counters.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getCounterRelationships = createSelector(
  (state) => state.counters.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getCounters = createSelector(
  (state) => state.counters.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && compact(ids.map((id) => dictionary[id]));
  }
);

export const getCounterByIds = createSelector(
  (state) => state.counters.dictionary,
  (dictionary) => {
    return Object.entries(dictionary).map((counter) => counter[1]);
  }
);

export const getCounterIdsForDateAndMenuDishIds = createSelector(
  (state) => state.menuDishes.dictionary,
  (state) => state.counters.dictionary,
  (_, singleDayId) => singleDayId,
  (_, menuDishIds) => menuDishIds,
  (menuDishesDictionary, countersDictionary, { singleDayId, menuDishIds }) => {
    const isMenuDishForSingleDayAndInMenuDishIds = (menuDish) => {
      const { attributes, id } = menuDish[1];
      return (
        attributes?.date_of_serving === singleDayId &&
        menuDishIds?.includes(parseInt(id))
      );
    };

    const menuDishes = Object.entries(menuDishesDictionary)?.filter(
      isMenuDishForSingleDayAndInMenuDishIds
    );

    const counterIds = [
      ...new Set(
        menuDishes?.map(([, menuDish]) => {
          return menuDish?.relationships?.counter?.data?.id;
        })
      ),
    ];

    return Object.entries(countersDictionary)
      ?.filter((counter) => counterIds?.includes(counter[1]?.id))
      ?.map((counter) => counter[0]);
  }
);

export default counterSlice.reducer;
