/*
Replace
dish with your resource Name :) Take care about different writings!
/!\ Dish dish Dishes dishs /!\
Keep an eye on pluralized namings in here :)

*/
/**
 *  Sagas for the Dishes resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 */

import { call, put, takeEvery, select } from "redux-saga/effects";
import buildHeaders from "../../utils/buildHeaders";
import { actions } from "./index";
import { storeIncluded } from "../dictionarySagas";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";
import DataLayerHelper from "../../utils/dataLayer";

/** Request cancelation */
let controller;
const cancelRequest = (reason) => {
  if (controller) {
    controller.abort();
    controller = null;
  }
};

/** Worker Sagas */

/** List Saga
 *  @description: connects to the getDish operation
 */
export function* list(action) {
  // Cancel request if pending
  cancelRequest();
  controller = new AbortController();

  const currentUser = yield select(
    (state) => state.professionalAccount.currentUser
  );
  let headers = buildHeaders(currentUser);
  let body = {
    "allergens[]": action.payload.allergens,
    "dish_bundles[]": action.payload.dishBundles,
    "recipe_types[]": action.payload.recipeTypes,
    sorting_filter: action.payload.sortingFilter,
    search: action.payload.search,
    page: action.payload.page,
    include_low_season_ingredients_names:
      action.payload.includeLowSeasonIngredientsNames,
    month: action.payload.month,
    // do we still need this?
    locationId: action.payload.locationId,
    date_start: action.payload.date_start,
    date_end: action.payload.date_end,
  };

  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(SwaggerClient.apis.Dishes.listDishes, body, {
      requestInterceptor: applyHeaders(headers),
      signal: controller.signal,
    });
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.listSuccess, payload: payload });
  } catch (e) {
    // Abort error
    if (e?.code === 20) {
      console.warn("Request aborted", controller);
    } else {
      yield put({ type: actions.listFail, payload: e });
      DataLayerHelper.addErrorEvent(
        "Load Dish List",
        e.error || e.message || "Dish Error"
      );
    }
  }
}

/** Show Saga
 *  @description: connects to the showDish operation
 *  @param {number} action.payload the Dish id
 */
export function* show(action) {
  const { dishId, locationId } = action.payload;
  const currentUser = yield select(
    (state) => state.professionalAccount.currentUser
  );
  let headers = buildHeaders(currentUser);
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Dishes.showDish,
      {
        id: dishId,
        location_id: locationId,
        include_low_season_ingredients_names:
          action.payload.includeLowSeasonIngredientsNames,
        month: action.payload.month,
      },
      { requestInterceptor: applyHeaders(headers) }
    );
    yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.showSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.showFail, payload: e });
  }
}

export function* create(action) {
  const { location_id, dish } = action.payload;
  const currentUser = yield select(
    (state) => state.professionalAccount.currentUser
  );
  let headers = buildHeaders(currentUser);
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Dishes.createDish,
      { location_id, dish },
      { requestInterceptor: applyHeaders(headers) }
    );

    yield put({ type: actions.createSuccess, payload: payload.obj.data });

    if (payload.obj.included) {
      yield* storeIncluded({ payload: payload.obj.included });
    }

    if (action.payload.callback) {
      action.payload.callback();
    }

    DataLayerHelper.addSuccessEvent("Create Dish", `data to log, maybe id?`);
  } catch (e) {
    yield put({ type: actions.createFail, payload: e });
  }
}

export function* update(action) {
  const { dishId, locationId, dish } = action.payload;
  const currentUser = yield select(
    (state) => state.professionalAccount.currentUser
  );
  let headers = buildHeaders(currentUser);
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Dishes.updateDish,
      { id: dishId, location_id: locationId, dish: dish },
      { requestInterceptor: applyHeaders(headers) }
    );

    yield put({ type: actions.updateSuccess, payload: payload.obj.data });

    yield* storeIncluded({ payload: payload.obj.included });

    if (action.payload.callback) {
      action.payload.callback();
    }

    DataLayerHelper.addSuccessEvent("Updated Dish");
  } catch (e) {
    yield put({ type: actions.updateFail, payload: e });
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* transactionsSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.update, update);
}
export default transactionsSaga;
